<template>
  <div class="o-Header">
    <div class="container">
      <div class="row hide-md">
        <div class="col-xs-12">
          <a href="/">
            <MobileHeader />
          </a>
        </div>
      </div>
      <div class="row display-md">
        <div class="col-xs-7 col-sm-7 col-md-5 col-lg-4">
          <router-link :to="{ name: 'Home'}">
            <img
              :src="infraqizLogo"
              class="o-Logo"
            />
          </router-link>
        </div>
        <div class="col-xs-5 col-sm-5 col-md-5 col-lg-8">
          <Links />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Links from '@/components/Links.vue';
import MobileHeader from '@/components/svg/MobileHeader.vue';

export default {
  name: 'HomeHeader',
  components: {
    Links,
    MobileHeader,
  },
  data: () => ({
    infraqizLogo: require('../assets/svg/logo_infraqidz.svg'),
    cloud1: require('../assets/svg/nuvem_infraquinta.svg'),
    cloud2: require('../assets/svg/nuvem_louleAdapta.svg'),
  }),
};
</script>

<style lang="scss" scoped>
.o-Header {
  margin: 2em 0;

  // @media only screen and (max-width: 767px){
  //   margin-top: 8em;
  // }
}

.o-Clouds {
  position: relative;

  @media only screen and (max-width: 767px){
    display: flex;
    justify-content: space-around;
  }

  &__cloud {
    position: absolute;
    height: 120px;
    width: 120px;
    z-index: 1;

    @media only screen and (max-width: 767px){
      position: relative;
      height: auto;
      width: 25%;
    }

    &.one {
      top: -55px;
      left: -15px;
      animation: float 6s ease-in-out infinite;

      @media only screen and (max-width: 767px){
        top: -50px;
        left: -10%;
      }
    }

    &.two {
      top: -45px;
      left: 75px;
      animation: float 6s ease-in-out infinite;
      animation-delay: 1s;

      @media only screen and (max-width: 767px){
        top: -40px;
        left: -40%;
      }
    }
  }
}

.o-Character {
  height: auto;

  &__wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    top: 2.5em;
  }

  &.left {
    margin-left: -2em;
  }
}

</style>
