<template>
  <div class="container">
    <div class="row">
      <div class="col-xs-12 col-sm-7 col-md-5">
        <div class="o-Home__welcome">
          <p>
            Nós somos o Edu e a Área e fazemos parte de uma equipa que tem uma missão muito importante:
          </p>
            <img :src="cuidar" />
          <p>
            Esta missão também deve ser tua e é muito fácil:
          </p>

          <ul class="o-Home__menu">
            <li class="o-Home__menuEntry">
              <router-link
                :to="{ name: 'Area', params: { section: 'aqua' }}"
                class="o-Home__menuLink"
              >
                <img :src="water" />
                <span class="o-Home__menuLabel">Poupa água</span>
              </router-link>
            </li>
            <li class="o-Home__menuEntry">
              <router-link
                :to="{ name: 'Area', params: { section: 'recicle' }}"
                class="o-Home__menuLink"
              >
                <img :src="recicle" />
                <span class="o-Home__menuLabel">Recicla, fazendo bem a separação</span>
              </router-link>
            </li>
            <li class="o-Home__menuEntry">
              <router-link
                :to="{ name: 'Area', params: { section: 'energy' }}"
                class="o-Home__menuLink"
              >
                <img :src="energy" />
                <span class="o-Home__menuLabel">Usa energia verde sem limites</span>
              </router-link>
            </li>
            <li class="o-Home__menuEntry">
              <router-link
                :to="{ name: 'Area', params: { section: 'nature' }}"
                class="o-Home__menuLink"
              >
                <img :src="nature" />
                <span class="o-Home__menuLabel">Preserva e cuida bem da natureza</span>
              </router-link>
            </li>
          </ul>

          <p>
            Vamos mostrar-te como se faz!
          </p>
        </div>
      </div>
      <!-- <div class="col-xs-12 col-sm-5 col-md-5">
        <div class="display-md">
          <div class="o-Home__characters">
            <div class="o-Home__character left">
              <Boy />
            </div>
            <div class="o-Home__character">
              <Girl />
            </div>
            <div class="o-Link_wrapper">
              <Links />
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import Links from '@/components/Links.vue';

export default {
  name: 'Home',
  components: {
    Links,
  },
  data: () => ({
    cuidar: require('../assets/svg/cuidar_ambiente.svg'),
    water: require('../assets/svg/icons/aqua.svg'),
    energy: require('../assets/svg/icons/energy.svg'),
    nature: require('../assets/svg/icons/nature.svg'),
    recicle: require('../assets/svg/icons/recicle.svg'),
  }),
};
</script>

<style lang="scss" scoped>
.o-Link_wrapper {
  position: absolute;
  bottom: 0;
  left: 1em;

  @media only screen and (min-width: 767px){
    display: none;
  }
}

.o-Home {
  &__welcome {
    background: #FFF;
    padding: 1em 2em;
    border-radius: 16px;
  }
  // Menu
  &__menu {
    padding: 0;
  }
  &__menuEntry {
    list-style-type: none;

    img {
      height: 2em;
    }
  }

  &__menuLink {
    display: inline-flex;
    align-items: center;
    text-decoration: none;
    color: #000;
    font-size: 1.2em;
    padding: 0.5em;
    border-radius: 16px;

    &:hover {
      background: #EEE;
    }
  }

  &__menuLabel {
    margin-left: 0.5em;
  }

  // Characters
  &__characters {
    position: fixed;
    display: flex;
    align-items: center;
    bottom: 1.5em;
    margin-left: -3em;
    width: 450px;

    @media only screen and (max-width: 767px){
      bottom: initial;
      width: 100%;
      margin: 0;
      left: 0;
    }
  }

  &__character {
    &.left {
      margin-right: -80px;
    }
  }
}
</style>
