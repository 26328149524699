import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    aquaDrawing: '{}',
  },
  mutations: {
    saveAqua(state, data) {
      state.aquaDrawing = data;
    },
  },
  actions: {
    saveDrawing({ commit }, { mutation, data }) {
      commit(mutation, data);
    },
  },
  modules: {
  },
});
