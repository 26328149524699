<template>
  <div class="o-Link__wrapper">
    <div class="o-Clouds">
      <img
        class="o-Clouds__cloud one"
        :src="cloud1"
      />
      <img
        class="o-Clouds__cloud two"
        :src="cloud2"
      />
    </div>
    <div class="o-Links">
      <ul>
        <li>
          <router-link to="/">Home</router-link>
        </li>
        <li>
          <router-link to="/sobre">Sobre InfraQidz</router-link>
        </li>
        <li>
          <router-link to="/materiais-didaticos">Materiais didáticos</router-link>
        </li>
        <!-- <li>
          <a href="#">Materiais didácticos</a>
        </li> -->
        <li>
          <router-link to="/contactos">Contactos</router-link>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
/* eslint-disable */

export default {
  name: 'Links',
  data: () => ({
    cloud1: require('../assets/svg/nuvem_infraquinta.svg'),
    cloud2: require('../assets/svg/nuvem_louleAdapta.svg'),
  }),
};
</script>

<style lang="scss" scoped>
.o-Clouds {
  position: relative;

  @media only screen and (max-width: 767px){
    display: flex;
    justify-content: space-around;
  }

  &__cloud {
    position: absolute;
    height: 120px;
    width: 120px;
    z-index: 1;

    @media only screen and (max-width: 767px){
      position: relative;
      height: auto;
      width: 120px;
    }

    &.one {
      top: -45px;
      left: -15px;
      animation: float 6s ease-in-out infinite;

      @media only screen and (max-width: 767px){
        top: 0;
        left: 0;
      }
    }

    &.two {
      top: -35px;
      left: 75px;
      animation: float 6s ease-in-out infinite;
      animation-delay: 1s;

      @media only screen and (max-width: 767px){
        top: 0;
        left: 0;
      }
    }
  }
}

.o-Link {
  &__wrapper {
    margin-left: 2em;
  }
}

.o-Links {
  position: absolute;
  margin-top: 3em;
  z-index: 10;

  @media only screen and (max-width: 767px){
    position: relative;
    margin-top: 0;
  }

  ul {
    padding: 0;
    li {
      list-style: none;
      margin-bottom: 0.25em;

      a {
        text-decoration: none;
        font-size: 1em;
        color: #FFF;
        &:hover {
          color: #EEE;
        }
      }
    }
  }
}
</style>
