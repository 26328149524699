import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  {
    path: '/area/:section',
    name: 'Area',
    component: () => import(/* webpackChunkName: "about" */ '../views/AreaSelect.vue'),
    props: {
      default: true,
      section: true,
    },
  },
  {
    path: '/sobre',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/materiais-didaticos',
    name: 'Materiais',
    component: () => import(/* webpackChunkName: "about" */ '../views/Materiais.vue'),
  },
  {
    path: '/contactos',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue'),
  },
];

const router = new VueRouter({
  routes,
});

export default router;
