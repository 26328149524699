<template>
  <div id="app">
    <div class="o-Page">
      <div class="o-Page__wrapper">
        <!-- Header -->
        <HomeHeader />
        <!-- Main Content -->
        <router-view/>
        <!-- Background -->
      </div>
      <div
        class="o-Page__background"
        :class="{ 'relative': !isHome && !isArea }"
      >
        <div class="o-Page__overlay">
          <HomeBackground />
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import HomeHeader from '@/components/HomeHeader.vue';
import Footer from '@/components/Footer.vue';
import HomeBackground from '@/components/svg/HomeBackground.vue';

export default {
  components: {
    HomeHeader,
    HomeBackground,
    Footer,
  },
  data: () => ({
    currentYear: new Date().getFullYear(),
  }),
  computed: {
    isHome() {
      return this.$route.name === 'Home';
    },
    isArea() {
      return this.$route.name === 'Area';
    },
  },
};
</script>

<style lang="scss">
.o-Page {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
  min-height: 100vh;

  &:before {
    position: absolute;
    display: block;
    top: 0;
    content: '';
    background-image: url('/home_pattern.png');
    background-repeat: repeat;
    background-size: 105px 105px;
    width: 100vw;
    height: 100vh;
    z-index: 0;
  }

  &__wrapper {
    margin: 0 auto;
    max-width: var(--max-width);
    min-height: 90vh;
    z-index: 20;

    @media only screen and (max-width: 767px){
      margin: 1em;
      padding: 0;
      max-width: initial;
    }
  }

  // Background
  &__background {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 0;

    &.relative {
      position: relative;
      margin-top: 2em;
    }

    @media only screen and (max-width: 767px){
      position: relative;
    }

    svg {
      margin-bottom: -4px;
    }
  }

  &__overlay {
    @media only screen and (max-width: 767px){
      display: none;
    }
  }

  // @media only screen and (max-width: 767px){
  //   display: block;
  //   width: 100%;
  //   height: auto;
  //   max-height: 100%;
  //   overflow: auto;
  // }
}

.o-MenuWrapper {
  max-width: 350px;
  @media only screen and (max-width: 767px){
    max-width: 90%;
    margin: 0 auto;
  }
}

.o-Modal {
  $parent: &;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;

  &.aqua {
    #{$parent}__title {
      color: #88c9df;
    }
  }
  &.recicle {
    #{$parent}__title {
      color: #d1db44;
    }
  }
  &.energy {
    #{$parent}__title {
      color: #53a96d;
    }
  }
  &.nature {
    #{$parent}__title {
      color: #a9d16b;
    }
  }

  &__headerWrapper,
  &__footerWrapper {
    width: 100%;
  }

  &__header,
  &__footer {
    display: flex;
    align-items: center;
    width: 95%;
  }

  &__title {
    display: flex;
    align-items: center;

    h2 {
      margin-left: 1em;
    }

    @media only screen and (max-width: 600px){
      margin-left: 0em;

      h2 {
        font-size: 1em;
      }
    }
  }
  &__headerActions {
    display: flex;
    margin-left: auto;
    width: 32px;

    @media only screen and (max-width: 600px){
      margin-right: 1em;
      padding-right: 1em;

      svg {
        border-radius: 50%;
        height: 32px;
        width: 32px;
      }
    }
  }

  &__header {
    margin: 1em;
    justify-content: flex-start;

    img {
      width: 48px;
    }

    @media only screen and (max-width: 600px){
      position: absolute;
      top: 0;
      z-index: 1;
      width: 100%!important;

      img {
        width: 32px;
      }
    }
  }
  &__footerWrapper {
    background: #45a4ba;
  }

  &__footer {
    justify-content: space-between;
    height: 4em;
    margin: 0 1em;
    color: #FFF;
    font-size: 1.2em;

    img {
      height: 32px;
    }

    @media only screen and (max-width: 600px){
      width: 90%;
    }
  }

  &__body {
    padding: 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media only screen and (max-width: 600px){
      margin: auto 0;

      h3 {
        font-size: 1em;
      }
    }
  }

  &__answers {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0;
  }

  &__answer {
    display: inline-block;
    list-style: none;
    margin-bottom: 2em;
    border-radius: 16px;

    > button {
      border: 2px solid #EEE;
      box-shadow: none;
      display: flex;
      align-items: center;
      color: #000;
      padding: 0.5em 0.75em;
      border-radius: 16px;
      background: #FBFBFB;
      font-size: 1.1em;

      @media only screen and (max-width: 600px){
        font-size: 1em;
      }

      img {
        height: 24px;
        margin-right: 0.5em;
      }

      &:not([disabled]):hover {
        border-color: #DDD;
        background: #EEE;
        cursor: pointer;
      }
    }
  }

  &__actions {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  &__action {
    border: none;
    box-shadow: none;
    font-size: 1.1em;
    border-radius: 16px;
    background: #88c9df;
    color: #000;
    padding: 0.5em 0.75em;

    &:disabled {
      background: #EEE;
      color: #AAA;
      &:hover {
        cursor: not-allowed;
      }
    }

    &:not([disabled]):hover {
      cursor: pointer;
      background: #45a4ba;
    }
  }

  &__success {
    display: flex;
    align-items: center;
    max-width: 400px;

    img {
      height: 200px;
    }

    h1, p {
      color: #fcb829;
    }

    h1 {
      margin-bottom: 0;
    }

    p {
      margin-top: 0.5em;
      font-size: 1.1em;
      font-weight: bold;
    }
  }
}

.o-Drawing {
  display: inline-flex;

  &__svg {
    width: 85vw;
    height: 85vh;

    svg {
      height: auto;
      width: 100%;
    }

    @media only screen and (max-width: 600px){
      display: flex;
      justify-content: center;
      transform: scale(0.7);

      svg {
        position: absolute;
        height: 80vh;
        width: auto;
      }
    }
  }

  &__colors {
    position: absolute;
    display: inline-flex;
    flex-direction: column;
    right: 3em;
    width: 155px;
    background: rgba(255, 255, 255, .8);
    border: 1px solid #DDD;
    border-radius: 4px;
    margin-top: .5em;

   @media only screen and (max-width: 992px){
      display: block;
      left: 5%;
      width: 90%;
      right: initial;
      bottom: 1em;
    }
  }

  &__action {
    padding: 0.5em;
    border: 2px solid #EEE;
    box-shadow: none;
    color: #000;
    padding: 0.5em 0.75em;
    border-radius: 16px;
    background: #FBFBFB;
    font-size: 1.1em;
    text-align: center;
    margin: 0.5em;

    @media only screen and (max-width: 992px){
      margin: 0.5em;
      width: 100px;
    }

    img {
      height: 24px;
      margin-right: 0.5em;
    }

    &:not([disabled]):hover {
      border-color: #DDD;
      background: #EEE;
      cursor: pointer;
    }
  }
}
.o-Colors {
  display: flex;
  flex-wrap: wrap;

}
.o-Color {
  margin: 0.5em 1em;
  padding: 20px;
  border-radius: 50%;
  border: 2px solid #DDD;

  @media only screen and (max-width: 992px){
    padding: 10px;
    margin: 0.25em;
  }

  &:hover {
    cursor: pointer;
    border-color: #AAA;
  }
}

// Center YT Video
.iframe-container {
  position: relative;
  width: 100%;
  padding-bottom: 56.25%;
  height: 0;

  iframe {
    position: absolute;
    top:0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// Tooltip CSS
.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-inner {
    background: #FFF;
    color: #000;
    border-radius: 16px;
    padding: 0.2em 0.5em;
    font-size: 1.2em;
    border: 2px solid #DDD;
  }

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: #DDD;
    z-index: 1;
  }

  &[x-placement^="top"] {
    margin-bottom: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 0 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      bottom: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 5px 5px 5px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &[x-placement^="right"] {
    margin-left: 5px;

    .tooltip-arrow {
      border-width: 5px 5px 5px 0;
      border-left-color: transparent !important;
      border-top-color: transparent !important;
      border-bottom-color: transparent !important;
      left: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &[x-placement^="left"] {
    margin-right: 5px;

    .tooltip-arrow {
      border-width: 5px 0 5px 5px;
      border-top-color: transparent !important;
      border-right-color: transparent !important;
      border-bottom-color: transparent !important;
      right: -5px;
      top: calc(50% - 5px);
      margin-left: 0;
      margin-right: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-inner {
      background: $color;
      color: black;
      padding: 24px;
      border-radius: 5px;
      box-shadow: 0 5px 30px rgba(black, .1);
    }

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity .15s, visibility .15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity .15s;
  }
}

[data-ref]:hover {
  cursor: pointer;
}

.highlight {
  font-weight: bold;
  color: var(--primary-color);
}

.display-md {
  display: block;

  @media only screen and (max-width: 767px){
    display: none!important;
  }
}

.hide-md {
  @media only screen and (min-width: 767px){
    display: none!important;
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-5px);
  }
  100% {
    transform: translatey(0px);
  }
}
</style>
